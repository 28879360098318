import React from 'react'
import get from 'lodash/get'
import { graphql } from 'gatsby'

import ContentfulPage from '../../components/Page/ContentfulPage'
import withContentfulBlocks from '../../components/withContentfulBlocks'
import Breadcrumb from '../../components/Breadcrumb'

class NewCalculator extends React.Component {
  render () {
    return (
      <ContentfulPage
        blocks={this.props.blocks}
        siteName={get(this.props, 'data.site.siteMetadata.siteName')}
        pageTitle={`${get(this.props, 'data.site.siteMetadata.siteName')}`}
        pageUrl={`${get(this.props, 'data.site.siteMetadata.url')}/our-services/new-calculator`}
        footerColor='white'
        contentAbove={
          <Breadcrumb
            items={[
              { title: 'Our services', path: `/our-services` },
              { title: 'calculator' }
            ]}
          />
        }
      />
    )
  }
}

export default withContentfulBlocks(NewCalculator)

export const pageQuery = graphql`
  query SLNewCalculatorQuery {
    site {
      ...SiteMetaDataFragment
    }
    page: contentfulPage(contentful_id: { eq: "3vcq5LuzwWV9nzOpxojViM" }) {
      ...PageBlocksFragment
    }
  }
`
